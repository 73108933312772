.dropdown{
    /* margin-left: 25%; */
    margin-top: 5%;
    padding-top: 20px;
    width: 30%;
     /* background-color: green; */ 
    text-align: center;
    font-weight:bold;
    font-size: 16px;
    padding: 10px 50px;
    margin: auto;
    display: block;
    margin-top: 30px;
    /* color: white; */
    cursor: pointer;

}

ul.ant-dropdown-menu.ant-dropdown-menu-root.ant-dropdown-menu-vertical.ant-dropdown-menu-light.css-dev-only-do-not-override-i0102m {
    /* background-color: green; */
    border-radius: 0px;
    /* color: white; */
    margin-top: 0px;
}
span.ant-dropdown-menu-title-content {
    /* color: white; */
}
span.ant-dropdown-menu-title-content:hover {
   /* background-color: rgb(2, 88, 2); */
}