.mainDiv{
    margin-top: 30px;
    padding-top: 25px;
    /* margin-left: 20%; */
    width: 30%;
    height: 50%;
    background-color: white;
    
   
}
.mainDiv2{
    margin-top: 30px;
    padding-top: 25px;
    /* margin-left: 20%; */
    width: 50%;
    height: 50%;

}
.alignments{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}
.mainDiv3{
    margin-top: 2%;
    /* margin-left: 20%; */
    width: 100%;
    height: 10%;
    padding: 20px;
    display: flex;
}
@media screen and (max-width:1199px) {
    .mainDiv3{
        display: block;
    }
}
.mainDiv4{
    /* margin-top: 1%; */
    margin-left: 5%;
    width: 40%;
    height: 10%;
    display: flex;
    flex-direction: row;
}
    
.middle_align{
    width: 75%;
    display: block;
    position: relative;
    left: 22%;
    margin-top: 139px;
}
@media screen and (max-width:992px) {
    .alignments{
        display: block;
    } 
    .mainDiv{
        width: auto;
    }
    .mainDiv2{
        width: auto;
    }
}
.tablets_{
position: relative;
left: 22%;
width: 75%;
}
.quiz_2{
    width: 100%;
}
.quiz_one{
    width: 100%;
}
.ant-plot-line path {
    stroke: green !important;
  }
  @media screen and (max-width:992px){
    .middle_align{
        margin: auto;
        left: 0;
        margin-top: 50px;
    }
  }