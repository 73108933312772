@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  ::-webkit-scrollbar {
    height: 0.6rem;
    width: 0.6rem;
    @apply bg-scroll-bar-color;
    border-radius: 0.5rem;
    -webkit-border-radius: 0.5rem;
    -moz-border-radius: 0.5rem;
    -ms-border-radius: 0.5rem;
    -o-border-radius: 0.5rem;
  }

  ::-webkit-scrollbar-thumb {
    @apply bg-scroll-thumb-color;
    border-radius: 0.5rem;
    -webkit-border-radius: 0.5rem;
    -moz-border-radius: 0.5rem;
    -ms-border-radius: 0.5rem;
    -o-border-radius: 0.5rem;
  }

  ::-webkit-scrollbar-thumb:hover {
    @apply bg-scroll-thumb-color-hover;
  }
}

@layer components {
  .custom-checkbox-wrapper label {
    @apply px-4 py-2 hover:bg-border;
  }
}
