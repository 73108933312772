.CardDiv{
    /* margin-left: 20%; */
    margin-top: 2%;
    padding: 1px 16px;
    font-family: "Poppins","sans-serif";
}
.CardDiv2{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.card1{
    margin-left: 20px;
    text-align: center;
    /* margin-top: 20px; */
}
/* @media screen and (max-width:1199px) {
    .CardDiv{
        margin-left: 10%;
    }
} */
.card2{
    margin-left: 20px;
    text-align: center;
    
}
@media screen and (max-width:1199px) {
 .card4{
    margin-top: 20px !important;
    margin-left: 0px 
}   
}
@media screen and (max-width:1199px) {
    .card5{
       margin-top: 20px !important;
   }   
   }
   @media screen and (max-width:992px) {
    .card{
     margin: auto;
     margin-bottom: 20px;
   }   
   .CardDiv2{
    display: block;
}


   }