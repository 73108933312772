.TableDiv{
    padding-top: 5%;
 margin: auto;
    width: auto;
    height:100%;
}
@media screen and (max-width:992px) {
    .TableDiv{
        margin-left: 0;
    }
}